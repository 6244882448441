import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AjaxLoaderService } from '../ajaxLoader/ajaxLoader.service';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor
{
	constructor(private router: Router, private ajaxLoader: AjaxLoaderService)
	{

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		this.ajaxLoader.show();

		let accessToken = sessionStorage.getItem("access_token");
		if (accessToken)
		{
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${accessToken}`
				}
			});
		}

		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) =>
			{
				if (event instanceof HttpResponse)
				{
					this.ajaxLoader.hide();
				}
			}),
			catchError((err: any) =>
			{
				if (err instanceof HttpErrorResponse)
				{
					// Unauthorized
					if (err.status === 401)
					{
						this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
					}

					// Forbidden
					if (err.status === 403)
					{
						this.router.navigate(['forbidden']);
					}
				}

				return throwError(() => err);
			}),
			finalize(() =>
			{
				this.ajaxLoader.hide();
			})
		);
	}
}