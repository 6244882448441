import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class NotificationService
{
    // constructor
    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
    {

    }

    // subscribe
    public subscribe(Subscription: PushSubscription)
    {
        return this.http.post(this.baseUrl + 'api/shared/notifications/subscribe', Subscription);
    }

    // unsubscribe
    public unsubscribe(Subscription: PushSubscription)
    {
        return this.http.post(this.baseUrl + 'api/shared/notifications/unsubscribe', Subscription);
    }

    // get subscription status
    public getSubscriptionStatus(Subscription: PushSubscription)
    {
        return this.http.post(this.baseUrl + 'api/shared/notifications/getSubscriptionStatus', Subscription);
    }

    // get push notifications devices
    public getPushNotificationsDevices()
    {
        return this.http.get(this.baseUrl + 'api/shared/notifications/getPushNotificationsDevices');
    }

    // send push message
    public sendPushMessage(PushMessage: any)
    {
        return this.http.post(this.baseUrl + 'api/shared/notifications/sendPushMessage', PushMessage);
    }

    // get user notifications
    public getUserNotifications(In: boolean, Out: boolean)
    {
        return this.http.get(this.baseUrl + 'api/shared/notifications/getUserNotifications?In=' + In + "&Out=" + Out);
    }

    // get notification
    public getNotification(id: number)
    {
        return this.http.get(this.baseUrl + 'api/shared/notifications/getNotification?Id=' + id);
    }

    // set notification as read
    public setNotificationAsRead(id?:number)
    {
        return this.http.post(this.baseUrl + 'api/shared/notifications/setNotificationAsRead', { id: id});
    }
}

export interface PushSubscription
{
    endPoint?: string | undefined;
    auth?: string | undefined;
    p256Dh?: string | undefined;
}

export interface NotificationModel
{
    title?: string | undefined;
    message?: string | undefined;
    url?: string | undefined;
}